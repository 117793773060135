@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Space Grotesk", sans-serif;
}
body{
    background-color:#1b1b1b;
    color: white;
}
input {
    height: 60px;
    padding: 10px 15px;
    margin-bottom: 15px;
    color: black;
}
button {
    width: 200px;
    outline: none;
    border: none;
    padding: 15px;
    cursor: pointer;
    font-size: 16px;
}
.login__container,
.signup__container,
.verify,
.dashboard {
    width: 100%;
    min-height: 100vh;
    padding: 50px 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.login__form,
.verify__form,
.signup__form {
    width: 50%;
    display: flex;
    flex-direction: column;
}
.loginBtn,
.signupBtn,
.codeBtn {
    background-color: #61dafb;
    color: #000;
    margin-bottom: 15px;
}
.signOutBtn {
    background-color: #c21010;
    color: #fff;
}
.link {
    cursor: pointer;
    color: #61dafb;
}
.code {
    width: 50%;
    text-align: center;
}
.verify__form {
    align-items: center;
}
.custom-radio {
    left: -8px;
    top: 2px;
    cursor: pointer;
    width: 17px;
    height: 17px;
    border: 2px solid #9a9a9a;
    border-radius: 50%;
    display: inline-block;
    position: relative;
}

.custom-radio::after {
    content: '';
    width: 12px;
    height: 12px;
    background: #f4b400;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s;
}

.profile-avatar {
    width: 200px;
    margin: 16px auto 20px;
    display: flex;
}

.button-container {
    display: flex;
}

.otherBtn {
    background-color: #61dafb;
    color: #000;
    margin-bottom: 15px;
    width: 20%;
}
.otherRedBtn {
    background-color: #c21010;
    color: #fff;
    margin-bottom: 15px;
    width: 20%;
}

.button-separation {
    margin-left: 10px;
}

.h2-separation {
    margin-top: 30px;
    margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
    .login__container,
    .signup__container,
    .verify {
        padding: 30px;
    }
}