.e-card {
    background-color: rgb(31 41 55);
    outline-style: solid;
    padding: 50px 50px 50px 25px;
    border-radius: 3pt;
    box-shadow: 0 0 0 1pt grey;
    outline-color: transparent;
    width: 35%;
    height: 10%;
    margin-bottom: 15px;
    margin-top: 15px;
}

.e-card-title {
    margin-top: -35px;
}

.e-card-subtitle {
    margin-bottom: 30px;
    color: rgb(128, 128, 128);
}